import React from "react"
import Contacts from "../components/Contacts"
import Footer from "../components/Footer"
import { VerzeichnisBackBuch1 } from "../components/Inhaltsverzeichnisse"
import { Link } from "gatsby"
import Head from "../components/Head"

//Image
import BackBuch1JPG from "../static/backbuch1.jpg"

class BackBuch extends React.Component {
  render() {
    return (
      <div>
        <Head />
        <div class="container">
          <div class="main">
            <div class="header">
              <div class="title">
                {" "}
                <a href="/">
                  <h1> </h1>
                </a>{" "}
              </div>
            </div>
            <div class="content">
              <div class="item">
                <h1>Das Backbuch - Band I</h1>
                <br />
                <p class="MsoNormal">
                  {" "}
                  <img
                    src={BackBuch1JPG}
                    border="0"
                    height="365"
                    width="400"
                    alt=""
                  />
                  <br />
                </p>
              </div>
              <div class="item">
                <h1>Inhaltsverzeichnis</h1>
                <br />
                <VerzeichnisBackBuch1 />
              </div>
              <div class="item">
                <h1>Bestellung</h1>
                <br />
                <p class="MsoNormal">
                  {" "}
                  Sie haben Interesse an Katharinas Backbuch - Band I? Bei uns
                  können Sie es gleich{" "}
                  <Link to="/bestellung/">online bestellen</Link>.<br />
                </p>
              </div>
            </div>
            <Contacts />
            <div class="clearer">
              <span />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default BackBuch
